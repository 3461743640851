/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminActivityResource } from '../AfClApiModels';
// @ts-ignore
import { AdminRedirectLink } from '../AfClApiModels';
// @ts-ignore
import { AdminRedirectLinkWithSubscriptionStatusCount } from '../AfClApiModels';
// @ts-ignore
import { AdminUpdateRedirectLinkRequest } from '../AfClApiModels';
// @ts-ignore
import { ExceptionsBasicApiException } from '../AfClApiModels';
// @ts-ignore
import { GetRedirectLinks200Response } from '../AfClApiModels';
/**
 * AdminRedirectLinkApi - axios parameter creator
 * @export
 */
export const AdminRedirectLinkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Admin Update Redirect Link
         * @param {number} linkId Numeric ID of the RedirectLink to find
         * @param {AdminUpdateRedirectLinkRequest} [adminUpdateRedirectLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateRedirectLink: async (linkId: number, adminUpdateRedirectLinkRequest?: AdminUpdateRedirectLinkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkId' is not null or undefined
            assertParamExists('adminUpdateRedirectLink', 'linkId', linkId)
            const localVarPath = `/v1/admin/redirect-links/{linkId}`
                .replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminUpdateRedirectLinkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Redirect Link
         * @param {number} linkId Numeric ID of the Redirect Link to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRedirectLink: async (linkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'linkId' is not null or undefined
            assertParamExists('deleteRedirectLink', 'linkId', linkId)
            const localVarPath = `/v1/admin/redirect-links/{linkId}`
                .replace(`{${"linkId"}}`, encodeURIComponent(String(linkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Redirect Link audit trail
         * @param {number} link Numeric ID of the redirect link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectLinkActivities: async (link: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'link' is not null or undefined
            assertParamExists('getRedirectLinkActivities', 'link', link)
            const localVarPath = `/v1/admin/redirect-links/{link}/activities`
                .replace(`{${"link"}}`, encodeURIComponent(String(link)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Redirect Links
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [campaignKeyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [publisherId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectLinks: async (publisherType?: 'INTERNAL' | 'EXTERNAL', programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, siteIds?: Array<number>, programManagerIds?: Array<number>, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, campaignKeyId?: number, from?: string, to?: string, publisherId?: number, page?: number, perPage?: number, sortDir?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/redirect-links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (programStatus !== undefined) {
                localVarQueryParameter['program_status'] = programStatus;
            }

            if (redirectLinkRequestStatus !== undefined) {
                localVarQueryParameter['redirect_link_request_status'] = redirectLinkRequestStatus;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (programManagerIds) {
                localVarQueryParameter['program_manager_ids[]'] = programManagerIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (campaignKeyId !== undefined) {
                localVarQueryParameter['campaign_key_id'] = campaignKeyId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort_dir'] = sortDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Redirect Links count per status
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [campaignKeyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectLinksCount: async (publisherType?: 'INTERNAL' | 'EXTERNAL', programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, siteIds?: Array<number>, programManagerIds?: Array<number>, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, campaignKeyId?: number, from?: string, to?: string, page?: number, perPage?: number, sortDir?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/redirect-links/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (programStatus !== undefined) {
                localVarQueryParameter['program_status'] = programStatus;
            }

            if (redirectLinkRequestStatus !== undefined) {
                localVarQueryParameter['redirect_link_request_status'] = redirectLinkRequestStatus;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (programManagerIds) {
                localVarQueryParameter['program_manager_ids[]'] = programManagerIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (campaignKeyId !== undefined) {
                localVarQueryParameter['campaign_key_id'] = campaignKeyId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort_dir'] = sortDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export Redirect Links to CSV
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [campaignKeyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [publisherId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectLinksExport: async (publisherType?: 'INTERNAL' | 'EXTERNAL', programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, siteIds?: Array<number>, programManagerIds?: Array<number>, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, campaignKeyId?: number, from?: string, to?: string, publisherId?: number, page?: number, perPage?: number, sortDir?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/redirect-links/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (publisherType !== undefined) {
                localVarQueryParameter['publisher_type'] = publisherType;
            }

            if (programStatus !== undefined) {
                localVarQueryParameter['program_status'] = programStatus;
            }

            if (redirectLinkRequestStatus !== undefined) {
                localVarQueryParameter['redirect_link_request_status'] = redirectLinkRequestStatus;
            }

            if (programIds) {
                localVarQueryParameter['program_ids[]'] = programIds;
            }

            if (siteIds) {
                localVarQueryParameter['site_ids[]'] = siteIds;
            }

            if (programManagerIds) {
                localVarQueryParameter['program_manager_ids[]'] = programManagerIds;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (campaignKeyId !== undefined) {
                localVarQueryParameter['campaign_key_id'] = campaignKeyId;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (publisherId !== undefined) {
                localVarQueryParameter['publisher_id'] = publisherId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sort_dir'] = sortDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminRedirectLinkApi - functional programming interface
 * @export
 */
export const AdminRedirectLinkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminRedirectLinkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Admin Update Redirect Link
         * @param {number} linkId Numeric ID of the RedirectLink to find
         * @param {AdminUpdateRedirectLinkRequest} [adminUpdateRedirectLinkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUpdateRedirectLink(linkId: number, adminUpdateRedirectLinkRequest?: AdminUpdateRedirectLinkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminRedirectLink>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUpdateRedirectLink(linkId, adminUpdateRedirectLinkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Redirect Link
         * @param {number} linkId Numeric ID of the Redirect Link to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRedirectLink(linkId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRedirectLink(linkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Redirect Link audit trail
         * @param {number} link Numeric ID of the redirect link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedirectLinkActivities(link: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminActivityResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedirectLinkActivities(link, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Redirect Links
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [campaignKeyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [publisherId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedirectLinks(publisherType?: 'INTERNAL' | 'EXTERNAL', programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, siteIds?: Array<number>, programManagerIds?: Array<number>, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, campaignKeyId?: number, from?: string, to?: string, publisherId?: number, page?: number, perPage?: number, sortDir?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRedirectLinks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedirectLinks(publisherType, programStatus, redirectLinkRequestStatus, programIds, siteIds, programManagerIds, regionIds, brandIds, verticalIds, campaignKeyId, from, to, publisherId, page, perPage, sortDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Redirect Links count per status
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [campaignKeyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedirectLinksCount(publisherType?: 'INTERNAL' | 'EXTERNAL', programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, siteIds?: Array<number>, programManagerIds?: Array<number>, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, campaignKeyId?: number, from?: string, to?: string, page?: number, perPage?: number, sortDir?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminRedirectLinkWithSubscriptionStatusCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedirectLinksCount(publisherType, programStatus, redirectLinkRequestStatus, programIds, siteIds, programManagerIds, regionIds, brandIds, verticalIds, campaignKeyId, from, to, page, perPage, sortDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export Redirect Links to CSV
         * @param {'INTERNAL' | 'EXTERNAL'} [publisherType] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'} [redirectLinkRequestStatus] 
         * @param {Array<number>} [programIds] 
         * @param {Array<number>} [siteIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [brandIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {number} [campaignKeyId] 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {number} [publisherId] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {'asc' | 'desc'} [sortDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRedirectLinksExport(publisherType?: 'INTERNAL' | 'EXTERNAL', programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED', programIds?: Array<number>, siteIds?: Array<number>, programManagerIds?: Array<number>, regionIds?: Array<number>, brandIds?: Array<number>, verticalIds?: Array<number>, campaignKeyId?: number, from?: string, to?: string, publisherId?: number, page?: number, perPage?: number, sortDir?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRedirectLinksExport(publisherType, programStatus, redirectLinkRequestStatus, programIds, siteIds, programManagerIds, regionIds, brandIds, verticalIds, campaignKeyId, from, to, publisherId, page, perPage, sortDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminRedirectLinkApi - factory interface
 * @export
 */
export const AdminRedirectLinkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminRedirectLinkApiFp(configuration)
    return {
        /**
         * 
         * @summary Admin Update Redirect Link
         * @param {AdminRedirectLinkApiAdminUpdateRedirectLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUpdateRedirectLink(requestParameters: AdminRedirectLinkApiAdminUpdateRedirectLinkRequest, options?: AxiosRequestConfig): AxiosPromise<AdminRedirectLink> {
            return localVarFp.adminUpdateRedirectLink(requestParameters.linkId, requestParameters.adminUpdateRedirectLinkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Redirect Link
         * @param {AdminRedirectLinkApiDeleteRedirectLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRedirectLink(requestParameters: AdminRedirectLinkApiDeleteRedirectLinkRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteRedirectLink(requestParameters.linkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Redirect Link audit trail
         * @param {AdminRedirectLinkApiGetRedirectLinkActivitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectLinkActivities(requestParameters: AdminRedirectLinkApiGetRedirectLinkActivitiesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AdminActivityResource>> {
            return localVarFp.getRedirectLinkActivities(requestParameters.link, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Redirect Links
         * @param {AdminRedirectLinkApiGetRedirectLinksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectLinks(requestParameters: AdminRedirectLinkApiGetRedirectLinksRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetRedirectLinks200Response> {
            return localVarFp.getRedirectLinks(requestParameters.publisherType, requestParameters.programStatus, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.siteIds, requestParameters.programManagerIds, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.campaignKeyId, requestParameters.from, requestParameters.to, requestParameters.publisherId, requestParameters.page, requestParameters.perPage, requestParameters.sortDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Redirect Links count per status
         * @param {AdminRedirectLinkApiGetRedirectLinksCountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectLinksCount(requestParameters: AdminRedirectLinkApiGetRedirectLinksCountRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminRedirectLinkWithSubscriptionStatusCount> {
            return localVarFp.getRedirectLinksCount(requestParameters.publisherType, requestParameters.programStatus, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.siteIds, requestParameters.programManagerIds, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.campaignKeyId, requestParameters.from, requestParameters.to, requestParameters.page, requestParameters.perPage, requestParameters.sortDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export Redirect Links to CSV
         * @param {AdminRedirectLinkApiGetRedirectLinksExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRedirectLinksExport(requestParameters: AdminRedirectLinkApiGetRedirectLinksExportRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getRedirectLinksExport(requestParameters.publisherType, requestParameters.programStatus, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.siteIds, requestParameters.programManagerIds, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.campaignKeyId, requestParameters.from, requestParameters.to, requestParameters.publisherId, requestParameters.page, requestParameters.perPage, requestParameters.sortDir, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for adminUpdateRedirectLink operation in AdminRedirectLinkApi.
 * @export
 * @interface AdminRedirectLinkApiAdminUpdateRedirectLinkRequest
 */
export interface AdminRedirectLinkApiAdminUpdateRedirectLinkRequest {
    /**
     * Numeric ID of the RedirectLink to find
     * @type {number}
     * @memberof AdminRedirectLinkApiAdminUpdateRedirectLink
     */
    readonly linkId: number

    /**
     * 
     * @type {AdminUpdateRedirectLinkRequest}
     * @memberof AdminRedirectLinkApiAdminUpdateRedirectLink
     */
    readonly adminUpdateRedirectLinkRequest?: AdminUpdateRedirectLinkRequest
}

/**
 * Request parameters for deleteRedirectLink operation in AdminRedirectLinkApi.
 * @export
 * @interface AdminRedirectLinkApiDeleteRedirectLinkRequest
 */
export interface AdminRedirectLinkApiDeleteRedirectLinkRequest {
    /**
     * Numeric ID of the Redirect Link to delete
     * @type {number}
     * @memberof AdminRedirectLinkApiDeleteRedirectLink
     */
    readonly linkId: number
}

/**
 * Request parameters for getRedirectLinkActivities operation in AdminRedirectLinkApi.
 * @export
 * @interface AdminRedirectLinkApiGetRedirectLinkActivitiesRequest
 */
export interface AdminRedirectLinkApiGetRedirectLinkActivitiesRequest {
    /**
     * Numeric ID of the redirect link
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinkActivities
     */
    readonly link: number
}

/**
 * Request parameters for getRedirectLinks operation in AdminRedirectLinkApi.
 * @export
 * @interface AdminRedirectLinkApiGetRedirectLinksRequest
 */
export interface AdminRedirectLinkApiGetRedirectLinksRequest {
    /**
     * 
     * @type {'INTERNAL' | 'EXTERNAL'}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly publisherType?: 'INTERNAL' | 'EXTERNAL'

    /**
     * 
     * @type {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'

    /**
     * 
     * @type {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly programManagerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly campaignKeyId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly publisherId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AdminRedirectLinkApiGetRedirectLinks
     */
    readonly sortDir?: 'asc' | 'desc'
}

/**
 * Request parameters for getRedirectLinksCount operation in AdminRedirectLinkApi.
 * @export
 * @interface AdminRedirectLinkApiGetRedirectLinksCountRequest
 */
export interface AdminRedirectLinkApiGetRedirectLinksCountRequest {
    /**
     * 
     * @type {'INTERNAL' | 'EXTERNAL'}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly publisherType?: 'INTERNAL' | 'EXTERNAL'

    /**
     * 
     * @type {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'

    /**
     * 
     * @type {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly programManagerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly campaignKeyId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AdminRedirectLinkApiGetRedirectLinksCount
     */
    readonly sortDir?: 'asc' | 'desc'
}

/**
 * Request parameters for getRedirectLinksExport operation in AdminRedirectLinkApi.
 * @export
 * @interface AdminRedirectLinkApiGetRedirectLinksExportRequest
 */
export interface AdminRedirectLinkApiGetRedirectLinksExportRequest {
    /**
     * 
     * @type {'INTERNAL' | 'EXTERNAL'}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly publisherType?: 'INTERNAL' | 'EXTERNAL'

    /**
     * 
     * @type {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'

    /**
     * 
     * @type {'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly redirectLinkRequestStatus?: 'NEW' | 'CONNECTED' | 'PENDING' | 'PENDING_SALES' | 'CLOSED'

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly programIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly siteIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly programManagerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly campaignKeyId?: number

    /**
     * 
     * @type {string}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly to?: string

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly publisherId?: number

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly perPage?: number

    /**
     * 
     * @type {'asc' | 'desc'}
     * @memberof AdminRedirectLinkApiGetRedirectLinksExport
     */
    readonly sortDir?: 'asc' | 'desc'
}

/**
 * AdminRedirectLinkApi - object-oriented interface
 * @export
 * @class AdminRedirectLinkApi
 * @extends {BaseAPI}
 */
export class AdminRedirectLinkApi extends BaseAPI {
    /**
     * 
     * @summary Admin Update Redirect Link
     * @param {AdminRedirectLinkApiAdminUpdateRedirectLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRedirectLinkApi
     */
    public adminUpdateRedirectLink(requestParameters: AdminRedirectLinkApiAdminUpdateRedirectLinkRequest, options?: AxiosRequestConfig) {
        return AdminRedirectLinkApiFp(this.configuration).adminUpdateRedirectLink(requestParameters.linkId, requestParameters.adminUpdateRedirectLinkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Redirect Link
     * @param {AdminRedirectLinkApiDeleteRedirectLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRedirectLinkApi
     */
    public deleteRedirectLink(requestParameters: AdminRedirectLinkApiDeleteRedirectLinkRequest, options?: AxiosRequestConfig) {
        return AdminRedirectLinkApiFp(this.configuration).deleteRedirectLink(requestParameters.linkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Redirect Link audit trail
     * @param {AdminRedirectLinkApiGetRedirectLinkActivitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRedirectLinkApi
     */
    public getRedirectLinkActivities(requestParameters: AdminRedirectLinkApiGetRedirectLinkActivitiesRequest, options?: AxiosRequestConfig) {
        return AdminRedirectLinkApiFp(this.configuration).getRedirectLinkActivities(requestParameters.link, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Redirect Links
     * @param {AdminRedirectLinkApiGetRedirectLinksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRedirectLinkApi
     */
    public getRedirectLinks(requestParameters: AdminRedirectLinkApiGetRedirectLinksRequest = {}, options?: AxiosRequestConfig) {
        return AdminRedirectLinkApiFp(this.configuration).getRedirectLinks(requestParameters.publisherType, requestParameters.programStatus, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.siteIds, requestParameters.programManagerIds, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.campaignKeyId, requestParameters.from, requestParameters.to, requestParameters.publisherId, requestParameters.page, requestParameters.perPage, requestParameters.sortDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Redirect Links count per status
     * @param {AdminRedirectLinkApiGetRedirectLinksCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRedirectLinkApi
     */
    public getRedirectLinksCount(requestParameters: AdminRedirectLinkApiGetRedirectLinksCountRequest = {}, options?: AxiosRequestConfig) {
        return AdminRedirectLinkApiFp(this.configuration).getRedirectLinksCount(requestParameters.publisherType, requestParameters.programStatus, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.siteIds, requestParameters.programManagerIds, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.campaignKeyId, requestParameters.from, requestParameters.to, requestParameters.page, requestParameters.perPage, requestParameters.sortDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export Redirect Links to CSV
     * @param {AdminRedirectLinkApiGetRedirectLinksExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminRedirectLinkApi
     */
    public getRedirectLinksExport(requestParameters: AdminRedirectLinkApiGetRedirectLinksExportRequest = {}, options?: AxiosRequestConfig) {
        return AdminRedirectLinkApiFp(this.configuration).getRedirectLinksExport(requestParameters.publisherType, requestParameters.programStatus, requestParameters.redirectLinkRequestStatus, requestParameters.programIds, requestParameters.siteIds, requestParameters.programManagerIds, requestParameters.regionIds, requestParameters.brandIds, requestParameters.verticalIds, requestParameters.campaignKeyId, requestParameters.from, requestParameters.to, requestParameters.publisherId, requestParameters.page, requestParameters.perPage, requestParameters.sortDir, options).then((request) => request(this.axios, this.basePath));
    }
}
