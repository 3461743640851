import { Route } from 'vue-lite-route';
import KeysMediaPage from '@/Pages/Admin/Keys/KeysMediaPage.vue';
import KeysCampaignPage from '@/Pages/Admin/Keys/KeysCampaignPage.vue';
import KeysSourcesPage from '@/Pages/Admin/Keys/KeysSourcesPage.vue';
import KeysLayout from '@/Layouts/Admin/KeysLayout.vue';
import { MiddlewareGroups } from '@/Router/Middleware/MiddlewareGroups';
import { ApiServicesEnum } from '@/Enums/ApiServicesEnum';

const AdvertisersPage = () => import('@/Pages/Admin/Advertisers/AdvertisersPage.vue');
const PublishersPage = () => import('@/Pages/Admin/Publishers/PublishersPage.vue');
const PublisherGeneralPage = () => import('@/Pages/Admin/Publishers/PublisherPage.vue');
const PublisherKycPage = () => import('@/Pages/Admin/Publishers/PublisherKycPage.vue');
const PublisherSitesPage = () => import('@/Pages/Admin/Publishers/PublisherSitesPage.vue');
const PublisherFinancialsPage = () => import('@/Pages/Admin/Publishers/PublisherFinancialsPage.vue');
const InvoicingPage = () => import('@/Pages/Admin/Invoicing/InvoicingPage.vue');
const PublishersLayout = () => import('@/Layouts/Admin/PublishersLayout.vue');
const SitesPage = () => import('@/Pages/Admin/Sites/SitesPage.vue');
const SitesViewPage = () => import('@/Pages/Admin/Sites/SitePage.vue');
const SitesAffiliationPage = () => import('@/Pages/Admin/Sites/SiteAffiliationPage.vue');
const SitesKycPage = () => import('@/Pages/Admin/Sites/SiteKycPage.vue');
const SitesLayout = () => import('@/Layouts/Admin/SitesLayout.vue');
const DataMappingPage = () => import('@/Pages/Admin/Operations/DataMappingPage.vue');
const AffiliateSystemPage = () => import('@/Pages/Admin/Operations/AffiliateSystemPage.vue');
const ProgramsLayout = () => import('@/Layouts/Admin/ProgramsLayout.vue');
const ProgramsPage = () => import('@/Pages/Admin/Programs/ProgramsPage.vue');
const AffiliateAccountsPage = () => import('@/Pages/Admin/Operations/AffiliateAccountsPage.vue');
const VoonixAccountsPage = () => import('@/Pages/Admin/Operations/VoonixAccountsPage.vue');
const LicenseAuditPage = () => import('@/Pages/Admin/Compliance/LicenseAuditPage.vue');
const ProgramGeneralPage = () => import('@/Pages/Admin/Programs/ProgramGeneralPage.vue');
const ProgramLicensePage = () => import('@/Pages/Admin/Programs/LicensesPage.vue');
const ProgramDetailsPage = () => import('@/Pages/Admin/Programs/ProgramDetailsPage.vue');
const ProgramDealsPage = () => import('@/Pages/Admin/Programs/ProgramDealsPage.vue');
const KeysPage = () => import('@/Pages/Admin/Keys/KeysPage.vue');
const SiteGroupViewPage = () => import('@/Pages/Admin/SiteGroups/SiteGroupViewPage.vue');
const SiteGroupLayout = () => import('@/Layouts/Admin/SiteGroupLayout.vue');
const SiteGroupSitesPage = () => import('@/Pages/Admin/SiteGroups/SiteGroupSitesPage.vue');
const SiteGroupKycPage = () => import('@/Pages/Admin/SiteGroups/SiteGroupKycPage.vue');
const LinkRequestsPage = () => import('@/Pages/Admin/Operations/LinkRequestsPage.vue');
const OperationsLayout = () => import('@/Layouts/Admin/OperationsLayout.vue');
const SiteCompliancePage = () => import('@/Pages/Admin/Compliance/SiteCompliancePage.vue');
const SiteAuditPage = () => import('@/Pages/Admin/Compliance/SiteAuditPage.vue');
const LicensesPage = () => import('@/Pages/Admin/Compliance/LicensesPage.vue');
const MarketsPage = () => import('@/Pages/Admin/Data/MarketsPage.vue');
const DataLayout = () => import('@/Layouts/Admin/DataLayout.vue');
const VerticalsPage = () => import('@/Pages/Admin/Data/VerticalsPage.vue');
const CorporationsPage = () => import('@/Pages/Admin/Corporations/CorporationsPage.vue');
const TranslationsPage = () => import('@/Pages/Admin/Widgets/TranslationsPage.vue');
const BrandsPage = () => import('@/Pages/Admin/Brands/BrandsPage.vue');
const PostbacksPage = () => import('@/Pages/Admin/Postbacks/PostbacksPage.vue');
const ApiDocsPage = () => import('@/Pages/Admin/ApiDocsPage.vue');
const ManagerRulesPage = () => import('@/Pages/Admin/ManagerRules/ManagerRulesPage.vue');
const RakecollectAffiliateSourcesPage = () => import('@/Pages/Admin/Rakecollect/AffiliateSourcesPage.vue');

export const RegisterAdminRoutes = () => {
    Route.group({ prefix: '/admin', name: 'admin', middleware: MiddlewareGroups.admin }, () => {

        Route.group({ prefix: '/sites' }, () => {
            Route.add('', { component: SitesPage }).name('.sites');
            Route.childrenGroup('/:id', { action: { component: SitesLayout } }, () => {
                Route.group({ name: '.sites' }, () => {
                    Route.add('', { component: SitesViewPage }).name('.view');
                    Route.add('kyc', { component: SitesKycPage }).name('.kyc');
                    Route.add('affiliation', { component: SitesAffiliationPage }).name('.affiliation');
                });
            }).clearName();
        });

        Route.group({ prefix: '/site-groups' }, () => {
            Route.childrenGroup('/:id', { action: { component: SiteGroupLayout } }, () => {
                Route.group({ name: '.site-groups' }, () => {
                    Route.add('', { component: SiteGroupViewPage }).name('.view');
                    Route.add('sites', { component: SiteGroupSitesPage }).name('.sites');
                    Route.add('kyc', { component: SiteGroupKycPage }).name('.kyc');
                });
            }).clearName();
        });

        Route.group({ prefix: '/publishers' }, () => {
            Route.add('', { component: PublishersPage }).name('.publishers');
            Route.childrenGroup('/:publisherId', { action: { component: PublishersLayout } }, () => {
                Route.add('', { component: PublisherGeneralPage }).name('.publishers.view');
                Route.add('kyc', { component: PublisherKycPage }).name('.publishers.kyc');
                Route.add('sites', { component: PublisherSitesPage }).name('.publishers.sites');
                Route.add('financials', { component: PublisherFinancialsPage }).name('.publishers.financials');
            }).clearName();
        });

        Route.group({ prefix: '/invoicing' }, () => {
            Route.add('', { component: InvoicingPage }).name('.invoicing');
        });

        Route.group({ prefix: '/manager-rules' }, () => {
            Route.add('', { component: ManagerRulesPage }).name('.manager-rules');
        });

        Route.group({ prefix: '/advertisers' }, () => {
            Route.add('', { component: AdvertisersPage }).name('.advertisers');
        });

        Route.group({ prefix: '/brands' }, () => {
            Route.add('', { component: BrandsPage }).name('.brands');
        });

        Route.group({ prefix: '/programs' }, () => {
            Route.add('', { component: ProgramsPage }).name('.programs');
            Route.childrenGroup('/:id', { action: { component: ProgramsLayout } }, () => {
                Route.add('general', { component: ProgramGeneralPage }).name('.programs.general');
                Route.add('licenses', { component: ProgramLicensePage }).name('.programs.licenses');
                Route.add('details', { component: ProgramDetailsPage }).name('.programs.details');
                Route.add('deals', { component: ProgramDealsPage }).name('.programs.deals');
            }).clearName();
        });

        Route.group({ prefix: '/compliance', name: '.compliance' }, () => {
            Route.childrenGroup('/', { action: { component: OperationsLayout } }, () => {
                Route.add('license-audit', { component: LicenseAuditPage }).name('.license-audit');
                Route.add('site-audit', { component: SiteAuditPage }).name('.site-audit');
                Route.add('licenses', { component: LicensesPage }).name('.licenses');
                Route.add('site-compliance', { component: SiteCompliancePage }).name('.site-compliance');
            });
        });

        Route.group({ prefix: '/operations', name: '.operations' }, () => {
            Route.add('/voonix-accounts', { component: VoonixAccountsPage }).name('.voonix-accounts');
            Route.add('/affiliate-accounts', { component: AffiliateAccountsPage }).name('.affiliate-accounts');
            Route.childrenGroup('/', { action: { component: OperationsLayout } }, () => {
                Route.add('link-requests', { component: LinkRequestsPage }).name('.link-requests');
                Route.add('data-mapping', { component: DataMappingPage }).name('.data-mapping');
                Route.add('affiliate-systems', { component: AffiliateSystemPage }).name('.affiliate-systems');
                Route.add('postbacks', { component: PostbacksPage }).name('.postbacks');
            });
        });

        Route.group({ prefix: '/data', name: '.data' }, () => {
            Route.childrenGroup('/', { action: { component: DataLayout } }, () => {
                Route.add('verticals', { component: VerticalsPage }).name('.verticals');
                Route.add('markets', { component: MarketsPage }).name('.markets');
            });
            Route.group({ prefix: '/corporations' }, () => {
                Route.add('', { component: CorporationsPage }).name('.corporations');
            });
            Route.group({ prefix: '/keys' }, () => {
                Route.add('media', { component: KeysPage }).name('.keys');
                Route.childrenGroup('/info/', { action: { component: KeysLayout } }, () => {
                    Route.add('media', { component: KeysMediaPage }).name('.keys.media');
                    Route.add('campaign', { component: KeysCampaignPage }).name('.keys.campaign');
                    Route.add('source', { component: KeysSourcesPage }).name('.keys.source');
                }).clearName();
            });
        });

        Route.group({ prefix: '/widgets', name: '.widgets' }, () => {
            Route.add('/translations', { component: TranslationsPage }).name('.translations');
        });

        Route.group({ prefix: '/docs', name: '.docs' }, () => {
            Route.group({ prefix: '/:version(v[0-9])' }, () => {
                Route.add(`/:service(${Object.values(ApiServicesEnum).join('|')})`, { component: ApiDocsPage }).name('.service-docs');
            });
        });

        Route.group({ prefix: '/rakecollect', name: '.rakecollect' }, () => {
            Route.add('/affiliate-sources', { component: RakecollectAffiliateSourcesPage }).name('.affiliate-sources');
        });
    });
};
