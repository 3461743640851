/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAdvertisersBasicPair200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminManagerResource } from '../AfClApiModels';
// @ts-ignore
import { AdminProgramExtendedResource } from '../AfClApiModels';
// @ts-ignore
import { AdminProgramResource } from '../AfClApiModels';
// @ts-ignore
import { AdminStoreProgramsRequest } from '../AfClApiModels';
// @ts-ignore
import { GetProgramsWithDeals200Response } from '../AfClApiModels';
// @ts-ignore
import { UpdateProgramRequest } from '../AfClApiModels';
/**
 * AdminProgramsApi - axios parameter creator
 * @export
 */
export const AdminProgramsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Program
         * @param {number} programId Numeric ID of the Program to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgram: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('deleteProgram', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details about a Program
         * @param {number} programId Numeric ID of the program to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram: async (programId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('getProgram', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Program Managers
         * @param {number} [siteId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {'MANAGER' | 'COORDINATOR'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramManagers: async (siteId?: number, regionIds?: Array<number>, verticalIds?: Array<number>, programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', type?: 'MANAGER' | 'COORDINATOR', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/programs/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (programStatus !== undefined) {
                localVarQueryParameter['program_status'] = programStatus;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Programs
         * @param {number} [siteId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [advertiserIds] 
         * @param {Array<number>} [ignoreIds] Ignore Program ids
         * @param {Array<number>} [brandIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrograms: async (siteId?: number, regionIds?: Array<number>, verticalIds?: Array<number>, advertiserIds?: Array<number>, ignoreIds?: Array<number>, brandIds?: Array<number>, page?: number, perPage?: number, name?: string, statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (siteId !== undefined) {
                localVarQueryParameter['site_id'] = siteId;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (advertiserIds) {
                localVarQueryParameter['advertiser_ids[]'] = advertiserIds;
            }

            if (ignoreIds) {
                localVarQueryParameter['ignore_ids[]'] = ignoreIds;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Programs with deals
         * @param {string} [name] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [advertiserIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [programCoordinatorIds] 
         * @param {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>} [dealTypes] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>} [statuses] 
         * @param {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>} [labelNames] 
         * @param {string} [sort] Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [withoutProgramManager] 
         * @param {boolean} [withoutProgramCoordinator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsWithDeals: async (name?: string, regionIds?: Array<number>, verticalIds?: Array<number>, advertiserIds?: Array<number>, programManagerIds?: Array<number>, programCoordinatorIds?: Array<number>, dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>, page?: number, perPage?: number, statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>, labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>, sort?: string, brandIds?: Array<number>, withoutProgramManager?: boolean, withoutProgramCoordinator?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/programs/with-deals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (advertiserIds) {
                localVarQueryParameter['advertiser_ids[]'] = advertiserIds;
            }

            if (programManagerIds) {
                localVarQueryParameter['program_manager_ids[]'] = programManagerIds;
            }

            if (programCoordinatorIds) {
                localVarQueryParameter['program_coordinator_ids[]'] = programCoordinatorIds;
            }

            if (dealTypes) {
                localVarQueryParameter['deal_types[]'] = dealTypes;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }

            if (labelNames) {
                localVarQueryParameter['label_names[]'] = labelNames;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (withoutProgramManager !== undefined) {
                localVarQueryParameter['without_program_manager'] = withoutProgramManager;
            }

            if (withoutProgramCoordinator !== undefined) {
                localVarQueryParameter['without_program_coordinator'] = withoutProgramCoordinator;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export Programs with deals to csv.
         * @param {string} [name] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [advertiserIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [programCoordinatorIds] 
         * @param {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>} [dealTypes] 
         * @param {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>} [statuses] 
         * @param {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>} [labelNames] 
         * @param {string} [sort] Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
         * @param {Array<number>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsWithDealsExport: async (name?: string, regionIds?: Array<number>, verticalIds?: Array<number>, advertiserIds?: Array<number>, programManagerIds?: Array<number>, programCoordinatorIds?: Array<number>, dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>, statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>, labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>, sort?: string, brandIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/programs/with-deals/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (regionIds) {
                localVarQueryParameter['region_ids[]'] = regionIds;
            }

            if (verticalIds) {
                localVarQueryParameter['vertical_ids[]'] = verticalIds;
            }

            if (advertiserIds) {
                localVarQueryParameter['advertiser_ids[]'] = advertiserIds;
            }

            if (programManagerIds) {
                localVarQueryParameter['program_manager_ids[]'] = programManagerIds;
            }

            if (programCoordinatorIds) {
                localVarQueryParameter['program_coordinator_ids[]'] = programCoordinatorIds;
            }

            if (dealTypes) {
                localVarQueryParameter['deal_types[]'] = dealTypes;
            }

            if (statuses) {
                localVarQueryParameter['statuses[]'] = statuses;
            }

            if (labelNames) {
                localVarQueryParameter['label_names[]'] = labelNames;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Store Program
         * @param {AdminStoreProgramsRequest} [adminStoreProgramsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeProgram: async (adminStoreProgramsRequest?: AdminStoreProgramsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/programs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminStoreProgramsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Program Logo
         * @param {number} programId Numeric ID of the program
         * @param {File} [acLogo] 
         * @param {string} [acLogoBackground] 
         * @param {string} [acLogoFrameColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAcProgramLogo: async (programId: number, acLogo?: File, acLogoBackground?: string, acLogoFrameColor?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('updateAcProgramLogo', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}/logo`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (acLogo !== undefined) { 
                localVarFormParams.append('ac_logo', acLogo as any);
            }
    
            if (acLogoBackground !== undefined) { 
                localVarFormParams.append('ac_logo_background', acLogoBackground as any);
            }
    
            if (acLogoFrameColor !== undefined) { 
                localVarFormParams.append('ac_logo_frame_color', acLogoFrameColor as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Program
         * @param {number} programId Numeric ID of the program to update
         * @param {UpdateProgramRequest} [updateProgramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgram: async (programId: number, updateProgramRequest?: UpdateProgramRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'programId' is not null or undefined
            assertParamExists('updateProgram', 'programId', programId)
            const localVarPath = `/v1/admin/programs/{programId}`
                .replace(`{${"programId"}}`, encodeURIComponent(String(programId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProgramRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminProgramsApi - functional programming interface
 * @export
 */
export const AdminProgramsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminProgramsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Program
         * @param {number} programId Numeric ID of the Program to delete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProgram(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProgram(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details about a Program
         * @param {number} programId Numeric ID of the program to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgram(programId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramExtendedResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgram(programId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Program Managers
         * @param {number} [siteId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'} [programStatus] 
         * @param {'MANAGER' | 'COORDINATOR'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramManagers(siteId?: number, regionIds?: Array<number>, verticalIds?: Array<number>, programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED', type?: 'MANAGER' | 'COORDINATOR', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminManagerResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramManagers(siteId, regionIds, verticalIds, programStatus, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Programs
         * @param {number} [siteId] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [advertiserIds] 
         * @param {Array<number>} [ignoreIds] Ignore Program ids
         * @param {Array<number>} [brandIds] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {string} [name] 
         * @param {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPrograms(siteId?: number, regionIds?: Array<number>, verticalIds?: Array<number>, advertiserIds?: Array<number>, ignoreIds?: Array<number>, brandIds?: Array<number>, page?: number, perPage?: number, name?: string, statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisersBasicPair200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPrograms(siteId, regionIds, verticalIds, advertiserIds, ignoreIds, brandIds, page, perPage, name, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Programs with deals
         * @param {string} [name] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [advertiserIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [programCoordinatorIds] 
         * @param {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>} [dealTypes] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>} [statuses] 
         * @param {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>} [labelNames] 
         * @param {string} [sort] Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
         * @param {Array<number>} [brandIds] 
         * @param {boolean} [withoutProgramManager] 
         * @param {boolean} [withoutProgramCoordinator] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramsWithDeals(name?: string, regionIds?: Array<number>, verticalIds?: Array<number>, advertiserIds?: Array<number>, programManagerIds?: Array<number>, programCoordinatorIds?: Array<number>, dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>, page?: number, perPage?: number, statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>, labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>, sort?: string, brandIds?: Array<number>, withoutProgramManager?: boolean, withoutProgramCoordinator?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProgramsWithDeals200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramsWithDeals(name, regionIds, verticalIds, advertiserIds, programManagerIds, programCoordinatorIds, dealTypes, page, perPage, statuses, labelNames, sort, brandIds, withoutProgramManager, withoutProgramCoordinator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export Programs with deals to csv.
         * @param {string} [name] 
         * @param {Array<number>} [regionIds] 
         * @param {Array<number>} [verticalIds] 
         * @param {Array<number>} [advertiserIds] 
         * @param {Array<number>} [programManagerIds] 
         * @param {Array<number>} [programCoordinatorIds] 
         * @param {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>} [dealTypes] 
         * @param {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>} [statuses] 
         * @param {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>} [labelNames] 
         * @param {string} [sort] Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
         * @param {Array<number>} [brandIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProgramsWithDealsExport(name?: string, regionIds?: Array<number>, verticalIds?: Array<number>, advertiserIds?: Array<number>, programManagerIds?: Array<number>, programCoordinatorIds?: Array<number>, dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>, statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>, labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>, sort?: string, brandIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProgramsWithDealsExport(name, regionIds, verticalIds, advertiserIds, programManagerIds, programCoordinatorIds, dealTypes, statuses, labelNames, sort, brandIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Store Program
         * @param {AdminStoreProgramsRequest} [adminStoreProgramsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeProgram(adminStoreProgramsRequest?: AdminStoreProgramsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeProgram(adminStoreProgramsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Program Logo
         * @param {number} programId Numeric ID of the program
         * @param {File} [acLogo] 
         * @param {string} [acLogoBackground] 
         * @param {string} [acLogoFrameColor] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAcProgramLogo(programId: number, acLogo?: File, acLogoBackground?: string, acLogoFrameColor?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAcProgramLogo(programId, acLogo, acLogoBackground, acLogoFrameColor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Program
         * @param {number} programId Numeric ID of the program to update
         * @param {UpdateProgramRequest} [updateProgramRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProgram(programId: number, updateProgramRequest?: UpdateProgramRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminProgramExtendedResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProgram(programId, updateProgramRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminProgramsApi - factory interface
 * @export
 */
export const AdminProgramsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminProgramsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Program
         * @param {AdminProgramsApiDeleteProgramRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProgram(requestParameters: AdminProgramsApiDeleteProgramRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteProgram(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details about a Program
         * @param {AdminProgramsApiGetProgramRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgram(requestParameters: AdminProgramsApiGetProgramRequest, options?: AxiosRequestConfig): AxiosPromise<AdminProgramExtendedResource> {
            return localVarFp.getProgram(requestParameters.programId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Program Managers
         * @param {AdminProgramsApiGetProgramManagersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramManagers(requestParameters: AdminProgramsApiGetProgramManagersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AdminManagerResource>> {
            return localVarFp.getProgramManagers(requestParameters.siteId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.programStatus, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Programs
         * @param {AdminProgramsApiGetProgramsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrograms(requestParameters: AdminProgramsApiGetProgramsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisersBasicPair200Response> {
            return localVarFp.getPrograms(requestParameters.siteId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.advertiserIds, requestParameters.ignoreIds, requestParameters.brandIds, requestParameters.page, requestParameters.perPage, requestParameters.name, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Programs with deals
         * @param {AdminProgramsApiGetProgramsWithDealsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsWithDeals(requestParameters: AdminProgramsApiGetProgramsWithDealsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GetProgramsWithDeals200Response> {
            return localVarFp.getProgramsWithDeals(requestParameters.name, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.advertiserIds, requestParameters.programManagerIds, requestParameters.programCoordinatorIds, requestParameters.dealTypes, requestParameters.page, requestParameters.perPage, requestParameters.statuses, requestParameters.labelNames, requestParameters.sort, requestParameters.brandIds, requestParameters.withoutProgramManager, requestParameters.withoutProgramCoordinator, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export Programs with deals to csv.
         * @param {AdminProgramsApiGetProgramsWithDealsExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProgramsWithDealsExport(requestParameters: AdminProgramsApiGetProgramsWithDealsExportRequest = {}, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getProgramsWithDealsExport(requestParameters.name, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.advertiserIds, requestParameters.programManagerIds, requestParameters.programCoordinatorIds, requestParameters.dealTypes, requestParameters.statuses, requestParameters.labelNames, requestParameters.sort, requestParameters.brandIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Store Program
         * @param {AdminProgramsApiStoreProgramRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeProgram(requestParameters: AdminProgramsApiStoreProgramRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminProgramResource> {
            return localVarFp.storeProgram(requestParameters.adminStoreProgramsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Program Logo
         * @param {AdminProgramsApiUpdateAcProgramLogoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAcProgramLogo(requestParameters: AdminProgramsApiUpdateAcProgramLogoRequest, options?: AxiosRequestConfig): AxiosPromise<AdminProgramResource> {
            return localVarFp.updateAcProgramLogo(requestParameters.programId, requestParameters.acLogo, requestParameters.acLogoBackground, requestParameters.acLogoFrameColor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Program
         * @param {AdminProgramsApiUpdateProgramRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProgram(requestParameters: AdminProgramsApiUpdateProgramRequest, options?: AxiosRequestConfig): AxiosPromise<AdminProgramExtendedResource> {
            return localVarFp.updateProgram(requestParameters.programId, requestParameters.updateProgramRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteProgram operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiDeleteProgramRequest
 */
export interface AdminProgramsApiDeleteProgramRequest {
    /**
     * Numeric ID of the Program to delete
     * @type {number}
     * @memberof AdminProgramsApiDeleteProgram
     */
    readonly programId: number
}

/**
 * Request parameters for getProgram operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiGetProgramRequest
 */
export interface AdminProgramsApiGetProgramRequest {
    /**
     * Numeric ID of the program to find
     * @type {number}
     * @memberof AdminProgramsApiGetProgram
     */
    readonly programId: number
}

/**
 * Request parameters for getProgramManagers operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiGetProgramManagersRequest
 */
export interface AdminProgramsApiGetProgramManagersRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminProgramsApiGetProgramManagers
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramManagers
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramManagers
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'}
     * @memberof AdminProgramsApiGetProgramManagers
     */
    readonly programStatus?: 'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'

    /**
     * 
     * @type {'MANAGER' | 'COORDINATOR'}
     * @memberof AdminProgramsApiGetProgramManagers
     */
    readonly type?: 'MANAGER' | 'COORDINATOR'
}

/**
 * Request parameters for getPrograms operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiGetProgramsRequest
 */
export interface AdminProgramsApiGetProgramsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly siteId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly advertiserIds?: Array<number>

    /**
     * Ignore Program ids
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly ignoreIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly perPage?: number

    /**
     * 
     * @type {string}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly name?: string

    /**
     * 
     * @type {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>}
     * @memberof AdminProgramsApiGetPrograms
     */
    readonly statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>
}

/**
 * Request parameters for getProgramsWithDeals operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiGetProgramsWithDealsRequest
 */
export interface AdminProgramsApiGetProgramsWithDealsRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly name?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly advertiserIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly programManagerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly programCoordinatorIds?: Array<number>

    /**
     * 
     * @type {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>

    /**
     * 
     * @type {number}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly perPage?: number

    /**
     * 
     * @type {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>

    /**
     * 
     * @type {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>

    /**
     * Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
     * @type {string}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly sort?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly withoutProgramManager?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof AdminProgramsApiGetProgramsWithDeals
     */
    readonly withoutProgramCoordinator?: boolean
}

/**
 * Request parameters for getProgramsWithDealsExport operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiGetProgramsWithDealsExportRequest
 */
export interface AdminProgramsApiGetProgramsWithDealsExportRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly name?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly regionIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly verticalIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly advertiserIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly programManagerIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly programCoordinatorIds?: Array<number>

    /**
     * 
     * @type {Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly dealTypes?: Array<'ACCOUNT' | 'GOLD' | 'SILVER' | 'CUSTOM'>

    /**
     * 
     * @type {Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly statuses?: Array<'COMING_SOON' | 'NEW' | 'ACTIVE' | 'INACTIVE' | 'DUPLICATED'>

    /**
     * 
     * @type {Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly labelNames?: Array<'VIP' | 'CAMPAIGN' | 'EXCLUSIVE_DEAL'>

    /**
     * Used for rt_index value. Available values: \&quot;+\&quot; or \&quot;-\&quot;
     * @type {string}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly sort?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof AdminProgramsApiGetProgramsWithDealsExport
     */
    readonly brandIds?: Array<number>
}

/**
 * Request parameters for storeProgram operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiStoreProgramRequest
 */
export interface AdminProgramsApiStoreProgramRequest {
    /**
     * 
     * @type {AdminStoreProgramsRequest}
     * @memberof AdminProgramsApiStoreProgram
     */
    readonly adminStoreProgramsRequest?: AdminStoreProgramsRequest
}

/**
 * Request parameters for updateAcProgramLogo operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiUpdateAcProgramLogoRequest
 */
export interface AdminProgramsApiUpdateAcProgramLogoRequest {
    /**
     * Numeric ID of the program
     * @type {number}
     * @memberof AdminProgramsApiUpdateAcProgramLogo
     */
    readonly programId: number

    /**
     * 
     * @type {File}
     * @memberof AdminProgramsApiUpdateAcProgramLogo
     */
    readonly acLogo?: File

    /**
     * 
     * @type {string}
     * @memberof AdminProgramsApiUpdateAcProgramLogo
     */
    readonly acLogoBackground?: string

    /**
     * 
     * @type {string}
     * @memberof AdminProgramsApiUpdateAcProgramLogo
     */
    readonly acLogoFrameColor?: string
}

/**
 * Request parameters for updateProgram operation in AdminProgramsApi.
 * @export
 * @interface AdminProgramsApiUpdateProgramRequest
 */
export interface AdminProgramsApiUpdateProgramRequest {
    /**
     * Numeric ID of the program to update
     * @type {number}
     * @memberof AdminProgramsApiUpdateProgram
     */
    readonly programId: number

    /**
     * 
     * @type {UpdateProgramRequest}
     * @memberof AdminProgramsApiUpdateProgram
     */
    readonly updateProgramRequest?: UpdateProgramRequest
}

/**
 * AdminProgramsApi - object-oriented interface
 * @export
 * @class AdminProgramsApi
 * @extends {BaseAPI}
 */
export class AdminProgramsApi extends BaseAPI {
    /**
     * 
     * @summary Delete Program
     * @param {AdminProgramsApiDeleteProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public deleteProgram(requestParameters: AdminProgramsApiDeleteProgramRequest, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).deleteProgram(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details about a Program
     * @param {AdminProgramsApiGetProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public getProgram(requestParameters: AdminProgramsApiGetProgramRequest, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).getProgram(requestParameters.programId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Program Managers
     * @param {AdminProgramsApiGetProgramManagersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public getProgramManagers(requestParameters: AdminProgramsApiGetProgramManagersRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).getProgramManagers(requestParameters.siteId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.programStatus, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Programs
     * @param {AdminProgramsApiGetProgramsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public getPrograms(requestParameters: AdminProgramsApiGetProgramsRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).getPrograms(requestParameters.siteId, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.advertiserIds, requestParameters.ignoreIds, requestParameters.brandIds, requestParameters.page, requestParameters.perPage, requestParameters.name, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Programs with deals
     * @param {AdminProgramsApiGetProgramsWithDealsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public getProgramsWithDeals(requestParameters: AdminProgramsApiGetProgramsWithDealsRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).getProgramsWithDeals(requestParameters.name, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.advertiserIds, requestParameters.programManagerIds, requestParameters.programCoordinatorIds, requestParameters.dealTypes, requestParameters.page, requestParameters.perPage, requestParameters.statuses, requestParameters.labelNames, requestParameters.sort, requestParameters.brandIds, requestParameters.withoutProgramManager, requestParameters.withoutProgramCoordinator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export Programs with deals to csv.
     * @param {AdminProgramsApiGetProgramsWithDealsExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public getProgramsWithDealsExport(requestParameters: AdminProgramsApiGetProgramsWithDealsExportRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).getProgramsWithDealsExport(requestParameters.name, requestParameters.regionIds, requestParameters.verticalIds, requestParameters.advertiserIds, requestParameters.programManagerIds, requestParameters.programCoordinatorIds, requestParameters.dealTypes, requestParameters.statuses, requestParameters.labelNames, requestParameters.sort, requestParameters.brandIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Store Program
     * @param {AdminProgramsApiStoreProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public storeProgram(requestParameters: AdminProgramsApiStoreProgramRequest = {}, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).storeProgram(requestParameters.adminStoreProgramsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Program Logo
     * @param {AdminProgramsApiUpdateAcProgramLogoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public updateAcProgramLogo(requestParameters: AdminProgramsApiUpdateAcProgramLogoRequest, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).updateAcProgramLogo(requestParameters.programId, requestParameters.acLogo, requestParameters.acLogoBackground, requestParameters.acLogoFrameColor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Program
     * @param {AdminProgramsApiUpdateProgramRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminProgramsApi
     */
    public updateProgram(requestParameters: AdminProgramsApiUpdateProgramRequest, options?: AxiosRequestConfig) {
        return AdminProgramsApiFp(this.configuration).updateProgram(requestParameters.programId, requestParameters.updateProgramRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
