/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdminGetAdvertisersBasicPair200Response } from '../AfClApiModels';
// @ts-ignore
import { AdminSubscriptionCampaignExtendedResource } from '../AfClApiModels';
// @ts-ignore
import { AdminSubscriptionCampaignWithLink } from '../AfClApiModels';
// @ts-ignore
import { ExceptionsBasicApiException } from '../AfClApiModels';
// @ts-ignore
import { GetSubscriptionCampaign200Response } from '../AfClApiModels';
// @ts-ignore
import { StoreSubscriptionCampaignRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateSubscriptionCampaign200Response } from '../AfClApiModels';
// @ts-ignore
import { UpdateSubscriptionCampaignFieldsRequest } from '../AfClApiModels';
// @ts-ignore
import { UpdateSubscriptionCampaignRequest } from '../AfClApiModels';
/**
 * AdminSubscriptionCampaignsApi - axios parameter creator
 * @export
 */
export const AdminSubscriptionCampaignsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create SubscriptionVerticalCompareRule Campaign for redirect link
         * @param {StoreSubscriptionCampaignRequest} [storeSubscriptionCampaignRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionCampaign: async (storeSubscriptionCampaignRequest?: StoreSubscriptionCampaignRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/subscription-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(storeSubscriptionCampaignRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SubscriptionVerticalCompareRule Campaign
         * @param {number} subscriptionCampaignId Numeric ID of the SubscriptionCampaign to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionCampaign: async (subscriptionCampaignId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionCampaignId' is not null or undefined
            assertParamExists('getSubscriptionCampaign', 'subscriptionCampaignId', subscriptionCampaignId)
            const localVarPath = `/v1/admin/subscription-campaigns/{subscriptionCampaignId}`
                .replace(`{${"subscriptionCampaignId"}}`, encodeURIComponent(String(subscriptionCampaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SubscriptionVerticalCompareRule Campaigns
         * @param {number} [redirectLinkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionCampaigns: async (redirectLinkId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/subscription-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirectLinkId !== undefined) {
                localVarQueryParameter['redirect_link_id'] = redirectLinkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Subscription Campaigns Basic Pair
         * @param {string} [campaignName] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionCampaignsBasicPair: async (campaignName?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/admin/subscription-campaigns/basic-pair`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (campaignName !== undefined) {
                localVarQueryParameter['campaign_name'] = campaignName;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update SubscriptionVerticalCompareRule Campaign
         * @param {number} subscriptionCampaignId Numeric ID of the SubscriptionCampaign to update
         * @param {UpdateSubscriptionCampaignRequest} [updateSubscriptionCampaignRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCampaign: async (subscriptionCampaignId: number, updateSubscriptionCampaignRequest?: UpdateSubscriptionCampaignRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionCampaignId' is not null or undefined
            assertParamExists('updateSubscriptionCampaign', 'subscriptionCampaignId', subscriptionCampaignId)
            const localVarPath = `/v1/admin/subscription-campaigns/{subscriptionCampaignId}`
                .replace(`{${"subscriptionCampaignId"}}`, encodeURIComponent(String(subscriptionCampaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionCampaignRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update Subscription Campaign Fields
         * @param {number} subscriptionCampaignId Numeric ID of the SubscriptionCampaign to update
         * @param {UpdateSubscriptionCampaignFieldsRequest} [updateSubscriptionCampaignFieldsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCampaignFields: async (subscriptionCampaignId: number, updateSubscriptionCampaignFieldsRequest?: UpdateSubscriptionCampaignFieldsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionCampaignId' is not null or undefined
            assertParamExists('updateSubscriptionCampaignFields', 'subscriptionCampaignId', subscriptionCampaignId)
            const localVarPath = `/v1/admin/subscription-campaigns/{subscriptionCampaignId}/fields`
                .replace(`{${"subscriptionCampaignId"}}`, encodeURIComponent(String(subscriptionCampaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionCampaignFieldsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminSubscriptionCampaignsApi - functional programming interface
 * @export
 */
export const AdminSubscriptionCampaignsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminSubscriptionCampaignsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create SubscriptionVerticalCompareRule Campaign for redirect link
         * @param {StoreSubscriptionCampaignRequest} [storeSubscriptionCampaignRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriptionCampaign(storeSubscriptionCampaignRequest?: StoreSubscriptionCampaignRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSubscriptionCampaign200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscriptionCampaign(storeSubscriptionCampaignRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SubscriptionVerticalCompareRule Campaign
         * @param {number} subscriptionCampaignId Numeric ID of the SubscriptionCampaign to find
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionCampaign(subscriptionCampaignId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubscriptionCampaign200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionCampaign(subscriptionCampaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get SubscriptionVerticalCompareRule Campaigns
         * @param {number} [redirectLinkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionCampaigns(redirectLinkId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AdminSubscriptionCampaignWithLink>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionCampaigns(redirectLinkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Subscription Campaigns Basic Pair
         * @param {string} [campaignName] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionCampaignsBasicPair(campaignName?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminGetAdvertisersBasicPair200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionCampaignsBasicPair(campaignName, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update SubscriptionVerticalCompareRule Campaign
         * @param {number} subscriptionCampaignId Numeric ID of the SubscriptionCampaign to update
         * @param {UpdateSubscriptionCampaignRequest} [updateSubscriptionCampaignRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionCampaign(subscriptionCampaignId: number, updateSubscriptionCampaignRequest?: UpdateSubscriptionCampaignRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSubscriptionCampaign200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionCampaign(subscriptionCampaignId, updateSubscriptionCampaignRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update Subscription Campaign Fields
         * @param {number} subscriptionCampaignId Numeric ID of the SubscriptionCampaign to update
         * @param {UpdateSubscriptionCampaignFieldsRequest} [updateSubscriptionCampaignFieldsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionCampaignFields(subscriptionCampaignId: number, updateSubscriptionCampaignFieldsRequest?: UpdateSubscriptionCampaignFieldsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AdminSubscriptionCampaignExtendedResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionCampaignFields(subscriptionCampaignId, updateSubscriptionCampaignFieldsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminSubscriptionCampaignsApi - factory interface
 * @export
 */
export const AdminSubscriptionCampaignsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminSubscriptionCampaignsApiFp(configuration)
    return {
        /**
         * 
         * @summary create SubscriptionVerticalCompareRule Campaign for redirect link
         * @param {AdminSubscriptionCampaignsApiCreateSubscriptionCampaignRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionCampaign(requestParameters: AdminSubscriptionCampaignsApiCreateSubscriptionCampaignRequest = {}, options?: AxiosRequestConfig): AxiosPromise<UpdateSubscriptionCampaign200Response> {
            return localVarFp.createSubscriptionCampaign(requestParameters.storeSubscriptionCampaignRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SubscriptionVerticalCompareRule Campaign
         * @param {AdminSubscriptionCampaignsApiGetSubscriptionCampaignRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionCampaign(requestParameters: AdminSubscriptionCampaignsApiGetSubscriptionCampaignRequest, options?: AxiosRequestConfig): AxiosPromise<GetSubscriptionCampaign200Response> {
            return localVarFp.getSubscriptionCampaign(requestParameters.subscriptionCampaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SubscriptionVerticalCompareRule Campaigns
         * @param {AdminSubscriptionCampaignsApiGetSubscriptionCampaignsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionCampaigns(requestParameters: AdminSubscriptionCampaignsApiGetSubscriptionCampaignsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AdminSubscriptionCampaignWithLink>> {
            return localVarFp.getSubscriptionCampaigns(requestParameters.redirectLinkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Subscription Campaigns Basic Pair
         * @param {AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPairRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionCampaignsBasicPair(requestParameters: AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPairRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AdminGetAdvertisersBasicPair200Response> {
            return localVarFp.getSubscriptionCampaignsBasicPair(requestParameters.campaignName, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update SubscriptionVerticalCompareRule Campaign
         * @param {AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCampaign(requestParameters: AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignRequest, options?: AxiosRequestConfig): AxiosPromise<UpdateSubscriptionCampaign200Response> {
            return localVarFp.updateSubscriptionCampaign(requestParameters.subscriptionCampaignId, requestParameters.updateSubscriptionCampaignRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update Subscription Campaign Fields
         * @param {AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignFieldsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionCampaignFields(requestParameters: AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignFieldsRequest, options?: AxiosRequestConfig): AxiosPromise<AdminSubscriptionCampaignExtendedResource> {
            return localVarFp.updateSubscriptionCampaignFields(requestParameters.subscriptionCampaignId, requestParameters.updateSubscriptionCampaignFieldsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSubscriptionCampaign operation in AdminSubscriptionCampaignsApi.
 * @export
 * @interface AdminSubscriptionCampaignsApiCreateSubscriptionCampaignRequest
 */
export interface AdminSubscriptionCampaignsApiCreateSubscriptionCampaignRequest {
    /**
     * 
     * @type {StoreSubscriptionCampaignRequest}
     * @memberof AdminSubscriptionCampaignsApiCreateSubscriptionCampaign
     */
    readonly storeSubscriptionCampaignRequest?: StoreSubscriptionCampaignRequest
}

/**
 * Request parameters for getSubscriptionCampaign operation in AdminSubscriptionCampaignsApi.
 * @export
 * @interface AdminSubscriptionCampaignsApiGetSubscriptionCampaignRequest
 */
export interface AdminSubscriptionCampaignsApiGetSubscriptionCampaignRequest {
    /**
     * Numeric ID of the SubscriptionCampaign to find
     * @type {number}
     * @memberof AdminSubscriptionCampaignsApiGetSubscriptionCampaign
     */
    readonly subscriptionCampaignId: number
}

/**
 * Request parameters for getSubscriptionCampaigns operation in AdminSubscriptionCampaignsApi.
 * @export
 * @interface AdminSubscriptionCampaignsApiGetSubscriptionCampaignsRequest
 */
export interface AdminSubscriptionCampaignsApiGetSubscriptionCampaignsRequest {
    /**
     * 
     * @type {number}
     * @memberof AdminSubscriptionCampaignsApiGetSubscriptionCampaigns
     */
    readonly redirectLinkId?: number
}

/**
 * Request parameters for getSubscriptionCampaignsBasicPair operation in AdminSubscriptionCampaignsApi.
 * @export
 * @interface AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPairRequest
 */
export interface AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPairRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPair
     */
    readonly campaignName?: string

    /**
     * 
     * @type {number}
     * @memberof AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPair
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPair
     */
    readonly perPage?: number
}

/**
 * Request parameters for updateSubscriptionCampaign operation in AdminSubscriptionCampaignsApi.
 * @export
 * @interface AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignRequest
 */
export interface AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignRequest {
    /**
     * Numeric ID of the SubscriptionCampaign to update
     * @type {number}
     * @memberof AdminSubscriptionCampaignsApiUpdateSubscriptionCampaign
     */
    readonly subscriptionCampaignId: number

    /**
     * 
     * @type {UpdateSubscriptionCampaignRequest}
     * @memberof AdminSubscriptionCampaignsApiUpdateSubscriptionCampaign
     */
    readonly updateSubscriptionCampaignRequest?: UpdateSubscriptionCampaignRequest
}

/**
 * Request parameters for updateSubscriptionCampaignFields operation in AdminSubscriptionCampaignsApi.
 * @export
 * @interface AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignFieldsRequest
 */
export interface AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignFieldsRequest {
    /**
     * Numeric ID of the SubscriptionCampaign to update
     * @type {number}
     * @memberof AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignFields
     */
    readonly subscriptionCampaignId: number

    /**
     * 
     * @type {UpdateSubscriptionCampaignFieldsRequest}
     * @memberof AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignFields
     */
    readonly updateSubscriptionCampaignFieldsRequest?: UpdateSubscriptionCampaignFieldsRequest
}

/**
 * AdminSubscriptionCampaignsApi - object-oriented interface
 * @export
 * @class AdminSubscriptionCampaignsApi
 * @extends {BaseAPI}
 */
export class AdminSubscriptionCampaignsApi extends BaseAPI {
    /**
     * 
     * @summary create SubscriptionVerticalCompareRule Campaign for redirect link
     * @param {AdminSubscriptionCampaignsApiCreateSubscriptionCampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionCampaignsApi
     */
    public createSubscriptionCampaign(requestParameters: AdminSubscriptionCampaignsApiCreateSubscriptionCampaignRequest = {}, options?: AxiosRequestConfig) {
        return AdminSubscriptionCampaignsApiFp(this.configuration).createSubscriptionCampaign(requestParameters.storeSubscriptionCampaignRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SubscriptionVerticalCompareRule Campaign
     * @param {AdminSubscriptionCampaignsApiGetSubscriptionCampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionCampaignsApi
     */
    public getSubscriptionCampaign(requestParameters: AdminSubscriptionCampaignsApiGetSubscriptionCampaignRequest, options?: AxiosRequestConfig) {
        return AdminSubscriptionCampaignsApiFp(this.configuration).getSubscriptionCampaign(requestParameters.subscriptionCampaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SubscriptionVerticalCompareRule Campaigns
     * @param {AdminSubscriptionCampaignsApiGetSubscriptionCampaignsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionCampaignsApi
     */
    public getSubscriptionCampaigns(requestParameters: AdminSubscriptionCampaignsApiGetSubscriptionCampaignsRequest = {}, options?: AxiosRequestConfig) {
        return AdminSubscriptionCampaignsApiFp(this.configuration).getSubscriptionCampaigns(requestParameters.redirectLinkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Subscription Campaigns Basic Pair
     * @param {AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPairRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionCampaignsApi
     */
    public getSubscriptionCampaignsBasicPair(requestParameters: AdminSubscriptionCampaignsApiGetSubscriptionCampaignsBasicPairRequest = {}, options?: AxiosRequestConfig) {
        return AdminSubscriptionCampaignsApiFp(this.configuration).getSubscriptionCampaignsBasicPair(requestParameters.campaignName, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update SubscriptionVerticalCompareRule Campaign
     * @param {AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionCampaignsApi
     */
    public updateSubscriptionCampaign(requestParameters: AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignRequest, options?: AxiosRequestConfig) {
        return AdminSubscriptionCampaignsApiFp(this.configuration).updateSubscriptionCampaign(requestParameters.subscriptionCampaignId, requestParameters.updateSubscriptionCampaignRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update Subscription Campaign Fields
     * @param {AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignFieldsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminSubscriptionCampaignsApi
     */
    public updateSubscriptionCampaignFields(requestParameters: AdminSubscriptionCampaignsApiUpdateSubscriptionCampaignFieldsRequest, options?: AxiosRequestConfig) {
        return AdminSubscriptionCampaignsApiFp(this.configuration).updateSubscriptionCampaignFields(requestParameters.subscriptionCampaignId, requestParameters.updateSubscriptionCampaignFieldsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
